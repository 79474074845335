export const getElementOffset = (el) => {
	let left = 0;
	let top = 0;

	while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
		left += el.offsetLeft - el.scrollLeft;
		top += el.offsetTop - el.scrollTop;
		el = el.offsetParent;
	}

	return { top, left };
};
