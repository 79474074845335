import axios from 'axios';
import { getCookie } from 'modules/utils/cookies';

axios.defaults.baseURL = window.location.href.includes('localhost')
	? 'http://localhost:5000/cadenmarinozzi-d87cc/us-central1/app'
	: 'https://us-central1-cadenmarinozzi-d87cc.cloudfunctions.net/app';

async function makeRequest(route, body) {
	try {
		const code = getCookie('code');

		const { data } = await axios.post(route, {
			code,
			...body,
		});

		return data;
	} catch (err) {
		console.error(err);

		return;
	}
}

export { makeRequest };
