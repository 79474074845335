import { Link } from 'react-router-dom';

export default ({ loggedIn }) => {
	return (
		<div className='flex flex-row gap-5 place-items-center justify-center p-2'>
			<span className='text-nowrap'>©2024 cadenmarinozzi</span>
			{loggedIn ? (
				<Link to='/upload'>Upload</Link>
			) : (
				<Link to='/login'>Login</Link>
			)}
		</div>
	);
};
