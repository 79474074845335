import LazyLoadedImage from 'Components/shared/LazyLoadedImage';
import { getMetadata } from 'modules/utils/metadata';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { images, lowresImages, nonBorderedImages } from 'modules/images';
import Theme from 'contexts/Theme';

export default () => {
	const [metadata, setMetadata] = useState({});
	const [loaded, setLoaded] = useState(false);
	const theme = useContext(Theme);

	const fetchMetadata = async () => {
		const metadata = await getMetadata();

		setMetadata(metadata);
		setLoaded(true);
	};

	useEffect(() => {
		fetchMetadata();
	}, []);

	if (!loaded) {
		return;
	}

	const { collectionId, photoId } = useParams();
	const collectionImages = images[collectionId];
	const collectionLowresImages = lowresImages[collectionId];
	const collectionMetadata = metadata[collectionId];
	const collectionNonBorderedImages = nonBorderedImages[collectionId];

	const path = `./${photoId}`;

	const img = collectionImages(path);
	const lowresImg = collectionLowresImages(path);
	const nonBorderedImg = collectionNonBorderedImages(path);

	return (
		<div className='h-full w-full p-5 flex flex-col gap-5 place-items-center'>
			<span className='text-xl'>{collectionMetadata.description}</span>
			<LazyLoadedImage
				key={path}
				src={theme === 'light' ? nonBorderedImg : img}
				className='w-full max-h-[100vh] pb-5 object-contain'
				alt={path}
				placeholderSrc={lowresImg}
			/>
		</div>
	);
};
