import { makeRequest } from 'modules/web/api';
import { useState } from 'react';

export default () => {
	const [title, setTitle] = useState('');
	const [description, setDescription] = useState('');
	const [images, setImages] = useState([]);
	const [cover, setCover] = useState([]);

	const submit = async () => {
		await makeRequest('/uploadImages', {
			images,
			title,
			description,
			cover,
		});

		window.location = '/home';
	};

	const handleFile = (file) => {
		return new Promise((resolve) => {
			let reader = new FileReader(); // built in API

			reader.addEventListener('load', (e) => {
				const base64 = e.target.result;

				resolve({
					image: base64,
					name: file.name,
				});
			});

			reader.readAsDataURL(file);
		});
	};

	const handleFilesUpload = async (e) => {
		let files = e.target.files; // JS FileList object

		const newImages = [];

		for (const file of files) {
			newImages.push(await handleFile(file));
		}

		setImages([...images, ...newImages]);
	};

	const imagesMap = images.map(({ image, name }, index) => (
		<img
			className='h-[10%] object-cover cursor-pointer transition-all duration-300 hover:brightness-50'
			key={index}
			src={image}
			onClick={() => setCover(name)}
		/>
	));

	return (
		<div className='flex flex-col gap-5 place-items-center m-5'>
			<input type='file' multiple onInput={handleFilesUpload} />
			{images && (
				<div className='flex flex-wrap gap-2 place-items-center'>
					{imagesMap}
				</div>
			)}
			<input
				className='max-w-xs p-2 bg-fill text-background'
				placeholder='Enter title'
				onChange={(e) => setTitle(e.target.value)}
				type='text'
			/>
			<input
				className='max-w-xs p-2 bg-fill text-background'
				placeholder='Enter description'
				onChange={(e) => setDescription(e.target.value)}
				type='text'
			/>
			<button className='bg-fill text-background p-2' onClick={submit}>
				Upload
			</button>
		</div>
	);
};
