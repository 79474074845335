import { setCookie } from 'modules/utils/cookies';
import { makeRequest } from 'modules/web/api';
import { useState } from 'react';

export default () => {
	const [code, setCode] = useState('');

	const submit = async () => {
		const loggedIn = await makeRequest('/login', { code });

		if (loggedIn) {
			setCookie('code', code);
			window.location = '/upload';
		}
	};

	return (
		<div className='flex flex-col bg-base-200 rounded-md place-items-center justify-center h-full m-5 gap-5'>
			<input
				className='max-w-xs p-2 bg-fill text-background'
				placeholder='Enter code'
				type='text'
				onChange={(e) => setCode(e.target.value)}
			/>
			<button className='bg-fill text-background p-2' onClick={submit}>
				Submit
			</button>
		</div>
	);
};
