import { useEffect, useRef } from 'react';

export default () => {
	const scroller = useRef();

	const handleScroll = () => {
		const main = document.querySelector('main');

		const element = scroller.current;
		if (!element) return;

		element.style.opacity = 1 - main.scrollTop / (window.innerHeight / 2);
	};

	useEffect(() => {
		const main = document.querySelector('main');
		main.addEventListener('scroll', handleScroll, true);

		return () => {
			main.removeEventListener('scroll', handleScroll, true);
		};
	}, []);

	return (
		<div
			ref={scroller}
			className='sm:flex text-textContent fixed w-full opacity-100 bottom-0 left-0 px-5 py-8 sm:py-5 h-40 sm:h-20 footer-gradient backdrop-blur-md flex-row justify-center place-items-center sm:place-items-end pointer-events-none'>
			<span className='text-shadow-lg shadow-black hidden sm:block z-10'>
				scroll for more
			</span>
			<span className='text-shadow-lg shadow-black block sm:hidden z-10'>
				touch an image to see the collection
			</span>
		</div>
	);
};
